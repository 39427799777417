
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";

@Component({})
export default class extends Vue {
  @Action("login/logout")
  public logout!: () => void;
  @Action("auth/deauthorize")
  public deauthorize!: () => void;

  @Mutation("auth/clearUser")
  public clearUser!: () => void;
  @Mutation("auth/clearToken")
  public clearToken!: () => void;

  private async created() {
    await Promise.all([
      // this.logout(),
      this.deauthorize(),
      this.clearToken()
    ]);
    this.$router.push({ name: "Login" });
  }
}
